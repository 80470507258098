import { Grid } from "@mui/material";
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import '../style/ConteudoFooter.css';

export default function ConteudoFooter() {
    return (
        <footer className="site-footer">
            <Grid className="footer-ati">
                <ul className="i">
                    <h2 className="title">ATI</h2>
                    <li className="sub-title">
                        Av. Pedro Freitas, 1900 – Centro <br />Administrativo
                    </li>
                    <li className="sub-title">
                        Bairro São Pedro
                    </li>
                    <li className="sub-title">
                        Teresina-PI – CEP: 64.018-900
                    </li>
                </ul>
            </Grid>
            <Grid className="footer-servico">
                <ul className="i">
                    <h2 className="title">Serviços</h2>
                    <li className="sub-title" >
                        <a className="sub-title-a" href="http://portal.pi.gov.br/servicos-para-o-cidadao/">Cidadão</a>
                    </li>
                    <li className="sub-title">
                        <a className="sub-title-a" href="https://portal.pi.gov.br/servicos-para-o-servidor/">Servidor Público</a>

                    </li>
                    <li className="sub-title">
                        <a className="sub-title-a" href="https://portal.pi.gov.br/servicos-para-a-empresa/">Gestão Pública</a>

                    </li>
                </ul>
            </Grid>
            <Grid className="footer-redes">
                <ul className="i">
                    <h2 className="title">Siga Nossas Redes</h2>
                    <li className="sub-title">
                        <a className="sub-title-a" href="https://www.youtube.com/channel/UCUvn6mz9Su40_xqyZjK5Onw"><YouTubeIcon style={{ color: 'red', marginBottom: '-6px' }} />  YouTube</a>
                    </li>
                    <li className="sub-title">
                        <a className="sub-title-a" href="https://www.instagram.com/ati.pi/?igshid=j8bw62bohz9g" ><InstagramIcon style={{ color: 'red', marginBottom: '-6px' }} /> Instagram</a>
                    </li>

                </ul>
            </Grid>

            <Grid className="footer-contato">
                <ul className="i">
                    <h2 className="title">Quer entrar em Contato com a <br /> ATI ou tem alguma dúvida?</h2>
                    <li className="sub-title" >
                        <a className="sub-title-a" href="https://portal.pi.gov.br/ati/fale-conosco/">Fale Conosco</a>
                    </li>
                    <li className="sub-title">
                        <a className="sub-title-a" href="https://portal.pi.gov.br/ati/posso-ajudar/">Posso Ajudar</a>

                    </li>
                </ul>
            </Grid>
            <Grid className="copyright">

            </Grid>
        </footer>

    )
}