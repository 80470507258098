import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function BasicMenu( props ) {
//   const [anchorEl, setAnchorEl] = React.useState(null);
  const {anchorEl, setAnchorEl, menuDinamico, hadleHeaderPageClick} = props;

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (h) => {
    hadleHeaderPageClick(h);
    setAnchorEl(null);
  };
  const onClose = () => {
    setAnchorEl(null);
  }    
  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {menuDinamico && menuDinamico.map((h) => (
          <MenuItem key={h.id} onClick={() => handleClose(h)}>
            {h.title.rendered}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
