import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Stack, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PageCard from './PageCard';
import CircularProgress from '@mui/material/CircularProgress';
import '../App.css';
import '../style/style.css';

export default function ConteudoBody(props) {
  const isMax = useMediaQuery('(min-width:1100px)');
  const { page, rootPage, body, action, txtFiltro, wait } = props;
  const [bodySort, setBodySort] = useState();
  const [showPageCards, setShowPageCards] = useState(true)

  const theme = useTheme();

  useEffect(() => {
    if (body) {
      let arr = [...body];
      arr.sort((a, b) => a.menu_order - b.menu_order);
      if (txtFiltro) {
        setShowPageCards(true);
      } else if (rootPage) {
        arr = arr.filter(item => item.parent === rootPage.id);
        if (arr && arr.length > 0) setShowPageCards(true);
        else setShowPageCards(false);
      } else {
        arr = arr.filter(item => item.parent === 0);
        setShowPageCards(true);
      }
      setBodySort(arr);
    }
  }, [body, rootPage, txtFiltro])

  const showWait = () => {
    // if (!page && (!bodySort || bodySort.length === 0)) return true;
    // return false;
    return wait;
  }
  return (
    <Box component="div" sx={{
      p: 1,
      // border: '1px dashed #92a8d1', 
      mt: 1, mb: 2, ml:2,
      color: "black"
    }}
    >
      {showWait() &&
        <Box sx={{ padding: '70px 0', textAlign: 'center' }}>
          <CircularProgress />
        </Box>}
      {rootPage && (page && rootPage.id !== page.id) &&
        // <iframe src={rootPage.link} width="100%" height="300" style={{border:'none'}}/>
        <Typography variant="body2" color="text.secondary" sx={{ marginRight:15 }}
            dangerouslySetInnerHTML={{ __html: rootPage.content.rendered }}  
          />
      }
      <Grid container spacing={1} >

      {page &&
          <Grid item xs={12} sx={{ tm: 4, color: theme.palette.mode === 'dark' ? 'white' : 'black',}} >
            <Typography variant="h5" gutterBottom >
              {page.title.rendered}
            </Typography>
            <Stack direction="row" >
              <div style={{ marginRight:15 }} dangerouslySetInnerHTML={{ __html: page.content.rendered }} />
              {/* <iframe src={page.link} width="100%" height="300" style={{border:'none'}}/> */}
            </Stack>
          </Grid>
        }
        
          {showPageCards && bodySort && bodySort.map((b) => (
            <Grid item xs={isMax?3:4} key={b.id}>
              <PageCard page={b} action={action} />
            </Grid>
          ))}
        
      </Grid>
      <Grid item xs={1}>
      </Grid>
    </Box>
  );
}
