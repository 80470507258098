import React, { useState } from "react";
import '../style/ConteudoHeader.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Button, Grid, List, ListItemButton, ListItemText, Menu, MenuItem, Skeleton, Stack } from "@mui/material";
import { useTheme } from "@emotion/react";
import MenuHeaderMobile from "./MenuHeaderMobile";
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BasicMenu from "./BasicMenu";

const MAX_MENU_FIXO = 4

export default function Header(props) {
    const { header, action, setTxtFiltro, leftMenu } = props;
    const isMax = useMediaQuery('(min-width:950px)');
    const [openMenu, setOpenMenu] = React.useState(null);
    const theme = useTheme();
    const [openPage, setOpenPage] = useState(true);

    const [anchorEl, setAnchorEl] = React.useState(null);

    
    const [menuFixo, setMenuFixo] = useState();
    const [menuDinamico, setMenuDinamico] = useState();

    const [selectedIndex, setSelectedIndex] = useState(1);
    React.useEffect(() => {

        if (leftMenu) {
            let arr = [];
            let arr2 = [];
            let index = 0;
            for (let x in leftMenu) {

                if (++index > MAX_MENU_FIXO) {
                    arr2.push(leftMenu[x])
                } else {
                    arr.push(leftMenu[x])
                };
            }
            setMenuDinamico(arr2);
            setMenuFixo(arr);
        }
    }, [leftMenu]);

    const hadleHeaderPageClick = (item) => {
        if (action) {
            action(item);
        }
    };
    const handleListItemClick = (event, item) => {
        setSelectedIndex(item.id);
        if (action) {
            action(item);
        }
        setOpenPage(!openPage);
    };
    return (
        <Grid>
            <Grid className="topo-menu" >
                <ul className="navbar-main">
                    {!header &&
                        <Skeleton variant="rectangular" width={450} height={36} />
                    }
                    {isMax && header.map((h) => (
                        <Button
                            key={h.id}
                            sx={{
                                color: '#6d6d6d',
                                textTransform: 'none'
                            }}
                            onClick={() => hadleHeaderPageClick(h)}
                        >
                            {h.title.rendered}
                        </Button>
                    ))}
                    {!isMax &&
                        <MenuHeaderMobile iconColor={'#6d6d6d'} header={header} hadleHeaderPageClick={hadleHeaderPageClick} />}
                </ul>
            </Grid>
            <Grid className="wrapper">
                <Grid className="brand_wrapper">
                    <Grid className="navbar-brand-wpz">
                        <img src="logo-pi-ati-2.png" width="30%" height="12%" onClick={() => window.location.reload()} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid className="main-navbar">
                {/* <Stack direction="row" spacing={2} > */}
                    {menuFixo && menuFixo.map((item) => (
                        <Button
                            key={item.id}
                            // className={"listaTexto"}
                            sx={{
                                color: '#fff',
                                textTransform: 'none'
                            }}
                            onClick={(event) => handleListItemClick(event, item)}
                        >
                            {item.title.rendered}
                        </Button>

                    ))}
         
                <IconButton  sx={{
                                color: '#fff',
                                textTransform: 'none'
                            }}
                            onClick={(event) => setOpenMenu(event.currentTarget)}
                            aria-label="upload picture" component="label">
                    <MoreVertIcon />
                </IconButton>
                <BasicMenu const anchorEl={openMenu} setAnchorEl={setOpenMenu} menuDinamico={menuDinamico} hadleHeaderPageClick={hadleHeaderPageClick}/>
            </Grid>
            
        </Grid>
    )
}