import { Box, Button, Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";


export default function EndBarBody(props) {
    const { children, page, action } = props;
    // const [activeStep, setActiveStep] = React.useState(0);
    const [pages, setPages] = useState();
    const [indexPage, setIndexPage] = useState();

    useEffect(() => {
        if (children) {
            let arr = children.filter(item => {
                return item.categories.length === 0
            });
            arr.sort((a, b) => a.menu_order - b.menu_order);
            let it = 0;
            let tol = 0
            for (let x in arr) {
                const pagina = arr[x];
                if (pagina.id == page?.id) {
                    it = x;
                }
                tol++;
            }
            setIndexPage({
                item: it,
                total: tol
            })
            setPages(arr);
        }
    }, [children])

    useEffect(() => {
        if (indexPage && page) {
            let it = 0;
            let tol = -1;
            for (let x in pages) {
                const pagina = pages[x];
                if (pagina.id == page.id)
                    it = x;
                tol++;
            }
            setIndexPage({
                item: it,
                total: tol
            })
        }
    }, [page])

    function handleClickButton(proximo) {
        let ind = parseInt(indexPage.item);
        ind = ind + proximo;
        if (action) action(pages[ind]);
    };

    return (
        <Box component="div" sx={{ p: 1 }} > 

            {/* <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start" spacing={2} > */}
            { (indexPage &&  indexPage.total > 1) &&
            <Stack direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                spacing={1}>
                {!(indexPage && indexPage.item == 0) &&
                    <Button variant='outlined' color='primary' onClick={() => handleClickButton(-1)} > Voltar</Button>}
                {!(indexPage && indexPage.item == indexPage.total) &&
                    <Button variant='contained' color='primary' onClick={() => handleClickButton(+1)} > Avançar</Button>}
                {/* {(indexPage && indexPage.item == indexPage.total) &&
                    <Button variant='contained' color='primary' onClick={handleClickButtonConcluido} > Concluido</Button>} */}
            </Stack>}
            {/* </Grid> */}
        </Box>
    );
}
