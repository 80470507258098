import React from 'react';
import Card from '@mui/material/Card';
import {Typography, CardHeader, IconButton} from '@mui/material';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import "../App.css";
export default function PageCard(props) {
  const { page, action } = props;

  const [media, setMedia] = React.useState();

  React.useEffect(()=>{
    if( page.featured_media !== 0){
      fetch('/api/?rest_route=/wp/v2/media/' + page.featured_media)
      .then((response) => response.json())
      .then((data) => {
        setMedia( data.guid.rendered)
      });
    }
  },[page])

  function handleClick() {
    if (action) action(page);
  }
 
  return (

    <Card sx={{ width: 240, height: 240, }}>
      <CardHeader
        action={
          <IconButton color="secondary" aria-label="ir pagina"  onClick={() => handleClick()}>
            <ReadMoreIcon />
          </IconButton>
        }
        title={page.title.rendered}

        subheader={
          
            page.featured_media === 0? 
            <Typography variant="caption" color="text.secondary"
              dangerouslySetInnerHTML={{ __html: page.excerpt.rendered }}  
            />
            :
            <a href="#" onClick={() => handleClick()}><img src={media} /></a>
          
        }
      />
    </Card>
    
  );
}
