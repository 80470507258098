import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import { Button, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { styled } from '@mui/material/styles';
import Check from '@mui/icons-material/Check';

const root = {
  parent:-1,
  title:{
    rendered: ''
  }
}

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#919EAB',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#919EAB',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: 'green',
    width: 38,
    height: 38,
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 38,
    height: 38,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));
function QontoStepIcon(props) {
  const { active, completed, className, index } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle">
          <Typography variant="h5" gutterBottom sx={{ ml:0, mt: 0.3 }} color='white'>
          {index}
        </Typography>
        </div>
      )}
    </QontoStepIconRoot>
  );
}
export default function HorizontalLinearStepper(props) {
  const {children, action, page, navegar} = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const [pages, setPages] = useState();
  const [pageNav, setPageNav] = React.useState();

  useEffect(()=>{
   
    if( children){
      let arr = children.filter( item => {
        return item.categories.length === 0 
      });
      arr.sort((a,b)=> a.menu_order - b.menu_order);
      setPages(arr);
      if( action) action(arr[0]);
    }
  },[children])

  useEffect(()=>{
    function montaNavRecursivo(p){
      fetch('/api/?rest_route=/wp/v2/pages/' + p.id)
      .then((response) => response.json())
      .then((data) => {
        let arr = [...pageNav];
        arr.push(data);
        setPageNav(arr);
      });
    }
    if (page && page.id != activeStep){
      setActiveStep(page.id)
    }
    if( page && page.categories.length === 0 ) return;
    if( page && page.parent === 0){
      setPageNav([root, page]);
    }else if( page && page.parent > 0){
      const item = pageNav.filter(p => p.id === page.id);
      if( !item || item.length === 0 ) montaNavRecursivo(page);
    }
  },[page])

  const handleStep = (step, item) => () => {
    setActiveStep(item?item.id:0);
    if( action) action(item);
  };

  return (
    <Box component="div" 
        sx={{ p: 1, 
          // border: '1px dashed #92a8d1' 
        }}
    > 
    <Box sx={{p:1, mb:1}}>
    {pageNav && pageNav.map((p,i) =>(
      <Button key={i} component="label" size="small" startIcon={p.parent === -1 ?<HomeIcon/>:<ArrowRightIcon/>}
        onClick={() =>navegar(p)}
      >
        {p.title.rendered}
      </Button>
    ))}
    </Box>
      <Stepper nonLinear activeStep={activeStep}>
        {pages && pages.map((item, index) => (
          <Step key={item.id} completed={activeStep == item.id}
          >
            <StepButton 
             icon={
              <QontoStepIcon index={index + 1} completed={activeStep == item.id}>
                <p>{index + 1}</p>
              </QontoStepIcon>
            }
              color="inherit" onClick={handleStep(index, item)} 
              sx={{ 
                //cx:24, cy:24, mx: 0.5, fontSize: 14, width: 300,  
              }}
            >
              {item.title? item.title.rendered:"-"}
            </StepButton> 
              {/* <StepLabel StepIconComponent={QontoStepIcon}>
                {item.title? item.title.rendered:"-"}
              </StepLabel> */}
              

          </Step>
        ))}
      </Stepper>

    </Box>
  );
}
